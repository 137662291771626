import { createApp } from 'vue';
import CalculatorPointsMiles from './components/CalculatorPointsMiles.vue';

createApp({
  components: {
    CalculatorPointsMiles,
  }
}).mount('#app');

// -------------
// Init jquery, popper.js, & Bootstrap
try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');
  require('bootstrap');
} catch (e) { console.log(e); }

// -------------
// Init Axios
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
