import Swiper, { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { q, forEachIfArray } from './utilities';

export function initGlobalLanding() {
  new Swiper('.swiper', {
    modules: [Pagination],
    slidesPerView: 'auto',
    loop: true,
    spaceBetween: 20,
    loopedSlides: 3,
    pagination: {
      el: '.swiper .swiper-pagination',
      clickable: true,
      bulletElement: 'button',
    },
    breakpoints: {
      480: {
        centeredSlides: true,
      },
    },
  });

  setInterval(() => {
    forEachIfArray(q('.flipper-1'), (el) => {
      el.classList.toggle('flip');
    });
  }, 5000);
}
