import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {clickAndEnterListener, forEachIfArray, hideSpinner, q, showSpinner,} from '../utilities';

export function initUnitedLandingPage() {
  new Swiper('.swiper-1', {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    loopedSlides: 3,
    pagination: {
      el: '.swiper-1 .swiper-pagination',
      clickable: true,
      bulletElement: 'button',
    },
    navigation: {
      nextEl: '.swiper-1 .swiper-button-next',
      prevEl: '.swiper-1 .swiper-button-prev',
    },
  });

  new Swiper('.swiper-2', {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    centeredSlides: true,
    pagination: {
      el: '.swiper-2 .swiper-pagination',
      clickable: true,
      bulletElement: 'button',
    },
    navigation: {
      nextEl: '.swiper-2 .swiper-button-next',
      prevEl: '.swiper-2 .swiper-button-prev',
    },
  });
}

export function initPartnerLinkFormPage() {
  const inputMemberNumber = q('input#member-number');
  const checkboxAgree = q('input[type="checkbox"]');
  const checkmarkEl = q('.checkmark');
  const formEl = q('form#link-form');
  const formSubmitBtn = q('input[type="submit"]');
  const errorMsgEl = q('.error-msg');
  const errorList = q('.error-msg > ul');
  let canSubmit = false;

  checkboxAgree.checked = false;
  formSubmitBtn.disabled = true;

  function checkmark() {
    hideError();
    if (checkboxAgree.checked) {
      canSubmit = false;
      checkboxAgree.checked = false;
      formSubmitBtn.disabled = true;
    } else {
      canSubmit = true;
      checkboxAgree.checked = true;
      formSubmitBtn.disabled = false;
    }
  }
  clickAndEnterListener(checkmarkEl, checkmark);
  checkboxAgree.addEventListener('input', () => {
    hideError();
    if (checkboxAgree.checked) {
      canSubmit = true;
      checkboxAgree.checked = true;
      formSubmitBtn.disabled = false;
    } else {
      canSubmit = false;
      checkboxAgree.checked = false;
      formSubmitBtn.disabled = true;
    }
  })

  function showError(errors) {
    hideError();
    for (const prop in errors) {
       const item = document.createElement('li');
        item.innerHTML = errors[prop][0];
        errorList.appendChild(item);
    }

    errorMsgEl.classList.add('show');
  }

  function hideError() {
    errorList.innerHTML = "";
    errorMsgEl.classList.remove('show');
  }

  if (inputMemberNumber) {
    inputMemberNumber.addEventListener('input', hideError);
  }


  function submitForm() {
    if (canSubmit) {
      let base = q('#baseUrl');
      let baseUrl = base.value;

      showSpinner();
      //TODO resetFormMessages()

      const formData = new FormData(formEl);

      // [ AJAX ]
      axios.post(formEl.getAttribute('action'), formData)

        .then(function(response) {
          console.log('axios post success');
          window.location.href = response.data.redirectUri;
        })

        .catch(function(error) {
          console.log('axios post error');
          const generalErrorUri = baseUrl + 'error';
          const redirectUri = error.response.data.redirectUri || generalErrorUri;

          switch (error.response.status) {
            // not authenticated
            case 401:
              window.location.href = baseUrl + 'signed-out';
              break;

            // already linked
            case 409:
              window.location.href = redirectUri;
              break;

            // session expired or invalid csrf token
            case 419:
              window.location.reload();
              break;

            // server-side validation failed (via BenefitChoiceService or FormRequest)
            case 422:
              console.dir(error.response)

              if (error.response.data.errors) {
                 showError(error.response.data.errors);
              } else {
                window.location.href = redirectUri;
              }

              break;

            // member not authorized to make a selection
            case 403:
              // fall through (maybe we should send them to 'not eligible', but the error page would make it easier for them to try again

            // general backend error
            case 500:
              // fall through

            default:
              window.location.href = redirectUri;
          }
        })

        .then(function() {
          hideSpinner();
        });
    }
  }
  clickAndEnterListener(formSubmitBtn, submitForm);
}

export function initPartnerPromoForm() {
  const formEl = q('form#promo-form')
  const formSubmitBtn = q('button[type="submit"]');

  function submitForm() {
     showSpinner();
     const formData = new FormData(formEl);

     axios.post(formEl.getAttribute('action'), formData)
        .then(function(response) {
          console.log('axios post success');
          window.location.href = response.data.redirectUri;
        }).catch(function(error){
          console.log(error);
          window.location.href = error.response.data.redirectUri;
       }).then(function() {
            hideSpinner();
       });
  }
  clickAndEnterListener(formSubmitBtn, submitForm);
}

export function initFlipCards() {
  forEachIfArray(q('.flip-container .front .flip-directions'), (el) => {
    clickAndEnterListener(el, () => {
      el.closest('.flip-container').classList.add('flip')
    });
  });
  forEachIfArray(q('.flip-container .back .flip-directions'), (el) => {
    clickAndEnterListener(el, () => {
      el.closest('.flip-container').classList.remove('flip')
    });
  });
}

export function initCathaySwipers() {
  new Swiper('.swiper', {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    loopedSlides: 3,
    pagination: {
      el: '.swiper .swiper-pagination',
      clickable: true,
      bulletElement: 'button',
    },
    navigation: {
      nextEl: '.swiper .swiper-button-next',
      prevEl: '.swiper .swiper-button-prev',
    },
  });
}

export function initBenefitsSwitcher() {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = q('#token').dataset.token;
  const partnerLevelSelector = q('.partner-dropdown');
  const marriottLevelSelectorText = q('.marriott-dropdown > button.dropdown-toggle > span');
  const partnerLevelSelectorText = q('.partner-dropdown > button.dropdown-toggle > span');
  const marriottLevel = q('.marriott-level');
  const partnerLevel = q('.partner-level');
  const benefitsWrap = q('.benefits-wrap');
  const benefitsContainer = q('#benefits');
  const notEligible = q('#not-eligible');
  const plus = document.createElement('div');
  const mobilePlus = document.createElement('div');
  const plusSpan = document.createElement('span');
  const spinner = document.createElement('div');

  spinner.classList.add('loading-spinner');
  plusSpan.innerText = '+';
  plus.classList.add('plus', 'position-absolute', 'd-none');
  mobilePlus.classList.add('plus', 'position-absolute', 'd-sm-none');
  plus.append(plusSpan)
  mobilePlus.append(plusSpan.cloneNode(true));
  benefitsWrap.append(plus, spinner);

  const selected = {
    'marriottMemberLevel': 0,
    'partnerMemberLevel': 0,
  }

  function enablePartnerSelector() {
    if (partnerLevelSelector.classList.contains('disabled')) {
      partnerLevelSelector.classList.remove('disabled');
    }
  }

  function filterPartnerSelections(showsPartnerLevels) {
    const valid = showsPartnerLevels.split(" ");
    partnerLevel.map(l => l.classList.add('hide'))
    selected.partnerMemberLevel = 0;
    partnerLevelSelectorText.classList.add('placeholder');
    const eligibleKrisflyerText = APP_singapore_content.landing.sec_2.krisflyer_list[0].item;
    partnerLevelSelectorText.innerText = eligibleKrisflyerText;
    benefitsContainer.innerHTML = '';
    notEligible.classList.remove('hide');
    if (valid) {
      valid.map(i => q('button#' + i).classList.remove('hide'));
    }
  }

  function setMarriottLevel(event) {
    selected.marriottMemberLevel = event.target.dataset.level;
    marriottLevelSelectorText.innerText = event.target.innerText;
    filterPartnerSelections(event.target.dataset.showsPartnerLevels);
    enablePartnerSelector();
    benefitsContainer.innerHTML = '';
    if(selected.partnerMemberLevel !== 0) {
      fetchBenefits();
    }
    marriottLevelSelectorText.classList.remove('placeholder');
  }
  function setPartnerLevel(event) {
    selected.partnerMemberLevel = event.target.dataset.level;
    partnerLevelSelectorText.innerText = event.target.innerText;
    benefitsContainer.innerHTML = '';
    fetchBenefits();
    partnerLevelSelectorText.classList.remove('placeholder');
  }

  function renderBenefits(data) {
    notEligible.classList.add('hide');
    let eligible = data['eligibleFor'];
    let count = 0;
    const moreThanOne =  eligible ? Object.keys(eligible).length > 2 : false;

    for (let i in eligible) {
      if (typeof eligible[i] == 'object') {
        count ++;
        const benefitCopy = eligible[i]
        const {card, flipper, front, back, headline, body, list} = buildBenefitTemplate();

        headline.innerHTML = benefitCopy['headline'];

        if (benefitCopy['styling_classes'].search('border') > 0) {
          const icon = document.createElement('img');
          icon.src = '/images/icon_accelerate.png';
          icon.classList.add('icon-accelerate');
          card.prepend(icon);
          card.classList.add(...benefitCopy['styling_classes'].split(" "));
        }

        body.innerHTML = benefitCopy['body'];

        benefitCopy['benefits'].forEach(benefit => {
          const item = document.createElement('li');
          item.classList.add('pb-0');
          item.innerHTML = benefit;
          list.append(item);
        });

        front.append(headline, body);
        back.append(list);

        flipper.append(front, back);
        card.append(flipper);

        if (moreThanOne && count === 1) {
          card.append(mobilePlus);
        }

        benefitsContainer.append(card);
      }
    }

    benefitsContainer.classList.add('benefit-cards-amount-' + count);
    if (moreThanOne) {
      plus.classList.add('d-sm-flex');
    } else {
      if (count === 0) {
        notEligible.classList.remove('hide');
      }
      benefitsContainer.classList.remove('benefit-cards-amount-2')
      plus.classList.remove('d-sm-flex');
    }
    hideSpinner()
  }

  function fetchBenefits() {
    showSpinner();
    window.axios.post('/singapore/en-gb/benefits-switcher', {
      marriottMemberLevel: selected.marriottMemberLevel,
      partnerMemberLevel: selected.partnerMemberLevel,
    }).then(function (response) {
      renderBenefits(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }

  function buildBenefitTemplate() {
    const card = document.createElement('div');
    card.classList.add('d-flex', 'position-relative');
    const flipper = document.createElement('div');
    flipper.classList.add('flipper', 'benefit-card', 'w-100');
    const front = document.createElement('div');
    front.classList.add('front');
    const back = document.createElement('div');
    back.classList.add(('back'));
    const headline = document.createElement('h3');
    headline.classList.add('subheading', 'fz-sm');
    const body = document.createElement('p');
    body.classList.add('fz-2xs');
    const list = document.createElement('ul');
    list.classList.add('list-style-none', 'text-center', 'fz-2xs', 'ml-0');
    return {card, flipper, front, back, headline, body, list};
  }

  clickAndEnterListener(marriottLevel, setMarriottLevel);
  clickAndEnterListener(partnerLevel, setPartnerLevel);
}
