import { q } from './utilities';

export function initCreativePage() {
  const partnerSections = q('.partner-section');
  const partnerSelectBtns = q('.partner-select button');

  function showPartnerSection(partner) {
    partnerSections.forEach((el) => {
      if (el.classList.contains(partner)) {
        el.classList.remove('hide');
      } else {
        el.classList.add('hide');
      }
    });
    partnerSelectBtns.forEach((el) => {
      if (el.classList.contains(partner)) {
        el.classList.add('highlight');
      } else {
        el.classList.remove('highlight');
      }
    });
  }

  showPartnerSection('united');

  partnerSelectBtns.forEach((el) => {
    el.addEventListener('click', () => {
      showPartnerSection(el.dataset.partner);
    });
  });
}
